import React from "react"

interface FooterProps {
    href?: string
    poweredBy?: string
}

export const Footer: React.FC<FooterProps> = ({
    href = "",
    poweredBy = "",
}) => {
    return (
        <footer className="px-8 py-2 border-t border-gray-700 bg-[#2F4F4F] flex items-center justify-center z-20">
            <div className="copyright text-center font-dosis text-xs text-white">
                &copy; {new Date().getFullYear()} | Powered by{" "}
                <a className="text-primary text-[#e60022]" href={href}>
                    {poweredBy}
                </a>
            </div>
        </footer>
    )
}
