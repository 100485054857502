import React, { useState } from "react"
import QRCode from "qrcode.react"
import { AiOutlineQrcode, AiOutlineClose } from "react-icons/ai"
import { Dialog } from "@headlessui/react"
import { MdDownload, MdShare } from "react-icons/md"
import { type UseAuthenticator } from "@aws-amplify/ui-react-core"
import { type AuthUser } from "aws-amplify/auth"

interface HeaderProps {
    companyName: string
    headerLogo?: string
    qrLink?: string
    qrImage?: string
    signOut?: UseAuthenticator["signOut"]
    user?: AuthUser
}

export const Header: React.FC<HeaderProps> = ({
    companyName,
    headerLogo,
    qrLink = "",
    qrImage = "",
    signOut,
    user,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const downloadQR = () => {
        const canvas = document.getElementById(
            "qrCodeCanvas",
        ) as HTMLCanvasElement
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        const downloadLink = document.createElement("a")
        downloadLink.href = pngUrl
        downloadLink.download = "QRCode.png"
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: companyName,
                    url: qrLink,
                })
                console.log("Content shared successfully")
            } catch (error) {
                console.error("Error sharing content", error)
            }
        } else {
            console.error("Web Share API is not supported in this browser.")
        }
    }

    const getSquareImage = (src: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.src = src
            image.onload = () => {
                const size = 40
                const canvas = document.createElement("canvas")
                canvas.width = size
                canvas.height = size
                const ctx = canvas.getContext("2d")

                if (ctx) {
                    const offsetX = Math.max(0, (size - image.width) / 2)
                    const offsetY = Math.max(0, (size - image.height) / 2)

                    ctx.fillStyle = "white"
                    ctx.fillRect(0, 0, size, size)

                    ctx.drawImage(
                        image,
                        offsetX,
                        offsetY,
                        image.width,
                        image.height,
                    )

                    resolve(canvas.toDataURL())
                } else {
                    reject(new Error("Could not create drawing context"))
                }
            }

            image.onerror = () => {
                reject(new Error("Failed to load image"))
            }
        })
    }

    const DialogPanel = () => (
        <Dialog
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            className="relative z-50"
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="bg-white rounded-lg max-w-sm mx-auto p-6 relative">
                    <button
                        onClick={() => setIsModalOpen(false)}
                        className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
                    >
                        <AiOutlineClose className="h-6 w-6" />
                    </button>
                    <QRCode
                        id="qrCodeCanvas"
                        value={qrLink}
                        size={200}
                        level={"H"}
                        includeMargin={true}
                        imageSettings={{
                            src: qrImage,
                            excavate: true,
                            height: 40,
                            width: 40,
                        }}
                    />
                    <div className="mt-4 flex justify-center">
                        <button
                            onClick={downloadQR}
                            className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-700 flex items-center justify-center"
                        >
                            <MdDownload className="mr-2" />
                            Download
                        </button>
                        <button
                            onClick={handleShare}
                            className="ml-3 bg-indigo-500 text-white px-3 py-2 rounded hover:bg-indigo-700 flex items-center justify-center"
                        >
                            <MdShare className="mr-2" />
                            Share
                        </button>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    )

    return (
        <div className="bg-[#2F4F4F] text-white p-4 flex items-center justify-between font-dosis z-30">
            <div className="flex items-center">
                {headerLogo && (
                    <img
                        src={headerLogo}
                        alt="Logo"
                        className="h-8 mr-2 rounded-lg"
                    />
                )}
                <span>{companyName}</span>
            </div>
            <div className="flex items-center">
                {qrLink && (
                    <>
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className="bg-transparent text-white p-2 rounded hover:bg-gray-700 flex items-center mr-4"
                        >
                            <AiOutlineQrcode className="h-5 w-5" />
                            <span className="absolute bottom-full mb-2 hidden px-3 py-1 bg-black text-white text-xs rounded-lg tooltip">
                                Download QR
                            </span>
                        </button>
                        <DialogPanel />
                    </>
                )}
                {user && signOut && (
                    <button
                        onClick={signOut}
                        className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-700 flex items-center justify-center"
                    >
                        Sign Out
                    </button>
                )}
            </div>
        </div>
    )
}
