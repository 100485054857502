import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

// Amplify.configure({
//     Auth: {
//         region: "YOUR_COGNITO_REGION",
//         userPoolId: "YOUR_USER_POOL_ID",
//         userPoolWebClientId: "YOUR_COGNITO_APP_CLIENT_ID",
//         // ... other configurations ...
//     },
// })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
