import { useState } from "react"
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3"

type UseAwsS3ReturnType = {
    getImageUrl: (key: string) => Promise<string | undefined>
    isLoading: boolean
    error: Error | null
}

export const useAwsS3 = (): UseAwsS3ReturnType => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const client: S3Client = new S3Client({
        region: process.env.REACT_APP_REGION || "",
        credentials: {
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "",
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY || "",
        },
    })

    const getImageUrl = async (key: string): Promise<string | undefined> => {
        setIsLoading(true)
        const command = new GetObjectCommand({
            Bucket: process.env.REACT_APP_BOTIFY_BUCKET || "",
            Key: key,
        })

        try {
            const response = await client.send(command)
            if (response.Body instanceof ReadableStream) {
                const blob = await new Response(response.Body).blob()
                const imageUrl = URL.createObjectURL(blob)
                setIsLoading(false)
                return imageUrl
            } else {
                console.error("Response body is not a stream.")
                setIsLoading(false)
            }
        } catch (err) {
            console.error(err)
            setError(err as Error)
            setIsLoading(false)
        }
    }

    return { getImageUrl, isLoading, error }
}
