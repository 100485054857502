import React, { useState, ChangeEvent, FormEvent, useRef } from "react"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import QRCode from "qrcode.react"
import { MdDownload, MdShare } from "react-icons/md"
import { Dialog } from "@headlessui/react"
import { getExtensionFromMimeType } from "../utils/getExtentionMimeType"
import { useAwsS3 } from "../utils/awsS3"
import { useFetch } from "../hooks/useFetch"
import { type AuthUser } from "aws-amplify/auth"
import { type UseAuthenticator } from "@aws-amplify/ui-react-core"

type BotFormData = {
    email: string
    bot_name: string
    bot_tag: string
    bot_logo: string
    bot_bg: string
    bot_kb: string
    custom_prompt: string
    logo_type: string
    bg_type: string
    kb_type: string
    bot_logo_name?: string
    bot_bg_name?: string
    bot_kb_name?: string
    emailValid: boolean
    bot_nameValid: boolean
    bot_tagValid: boolean
    bot_logoValid: boolean
    bot_bgValid: boolean
    bot_kbValid: boolean
    bot_logo_file?: File
    bot_bg_file?: File
    bot_kb_file?: File
}

type BackendResponse = {
    success: boolean
    message?: string
    url?: string
}

interface CreateBotProps {
    user: AuthUser
    signOut: UseAuthenticator["signOut"]
}

const CreateBot: React.FC<CreateBotProps> = ({ user, signOut }) => {
    const [formData, setFormData] = useState<BotFormData>({
        email: "",
        bot_name: "",
        bot_tag: "",
        bot_logo: "",
        bot_bg: "",
        bot_kb: "",
        custom_prompt: "",
        logo_type: "",
        bg_type: "",
        kb_type: "",
        bot_logo_name: "",
        bot_bg_name: "",
        bot_kb_name: "",
        emailValid: true,
        bot_nameValid: true,
        bot_tagValid: true,
        bot_logoValid: true,
        bot_bgValid: true,
        bot_kbValid: true,
    })
    const [qrLink, setQrLink] = useState("")
    const [qrLogo, setQrLogo] = useState("")
    const [error, setError] = useState<string | null>(null)
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false)
    const {
        fetchWithToken,
        isLoading: fetchLoading,
        error: errorFetch,
    } = useFetch()
    const { getImageUrl, isLoading, error: awsError } = useAwsS3()

    const showErrorDialog = (errorMessage: string) => {
        setError(errorMessage)
        setIsErrorDialogOpen(true)
    }
    const handleCloseErrorDialog = () => {
        setIsErrorDialogOpen(false)
        setError(null)
    }

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const botLogoRef = useRef<HTMLInputElement>(null)
    const botBgRef = useRef<HTMLInputElement>(null)
    const botKbRef = useRef<HTMLInputElement>(null)

    const convertToBase64 = (file: File): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const base64 = reader.result as string
                const base64WithoutPrefix = base64.split(",")[1] || base64
                resolve(base64WithoutPrefix)
            }
            reader.onerror = (error) => reject(error)
        })

    const handleFileChange = (
        e: ChangeEvent<HTMLInputElement>,
        fileType: "bot_logo" | "bot_bg" | "bot_kb",
    ) => {
        const file = e.target.files ? e.target.files[0] : null
        if (file) {
            setFormData({
                ...formData,
                [fileType]: file,
                [`${fileType}_file`]: file,
                [`${fileType}_name`]: file.name,
                [`${fileType}Valid`]: true,
            })
        } else {
            setFormData({ ...formData, [`${fileType}Valid`]: false })
        }
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        setError("")
        const allValid =
            formData.bot_nameValid &&
            formData.bot_tagValid &&
            formData.bot_logoValid &&
            formData.bot_bgValid &&
            formData.bot_kbValid
        if (!allValid) {
            // Update the state to show validation messages for any field that's invalid
            setFormData({
                ...formData,
                emailValid: !!(user?.signInDetails?.loginId || ""),
                bot_nameValid: !!formData.bot_name.trim(),
                bot_tagValid: !!formData.bot_tag.trim(),
                bot_logoValid: !!formData.bot_logo,
                bot_bgValid: !!formData.bot_bg,
                bot_kbValid: !!formData.bot_kb,
            })
            return
        }

        const botLogoExt = await getExtensionFromMimeType(
            formData.bot_logo_file?.type || "",
        )
        const botKBExt = await getExtensionFromMimeType(
            formData.bot_kb_file?.type || "",
        )
        const botBgExt = await getExtensionFromMimeType(
            formData.bot_bg_file?.type || "",
        )

        try {
            const botLogoBase64 = formData.bot_logo_file
                ? await convertToBase64(formData.bot_logo_file)
                : ""
            const botBgBase64 = formData.bot_bg_file
                ? await convertToBase64(formData.bot_bg_file)
                : ""
            const botKbBase64 = formData.bot_kb_file
                ? await convertToBase64(formData.bot_kb_file)
                : ""

            const payload = {
                bot_kb: botKbBase64,
                kb_type: botKBExt,
                bot_bg: botBgBase64,
                bg_type: botBgExt,
                bot_logo: botLogoBase64,
                logo_type: botLogoExt,
                email: user?.signInDetails?.loginId || "",
                bot_name: formData.bot_name,
                bot_tag: formData.bot_tag,
                custom_prompt: formData.custom_prompt,
            }

            console.log("Sending data to backend:", payload)
            try {
                const apiUrl =
                    process.env.REACT_APP_API_ENDPOINT + "/create_bot"

                const dataRaw = await fetchWithToken(apiUrl, {
                    method: "POST",
                    body: JSON.stringify(payload),
                    headers: { "Content-Type": "application/json" },
                })

                if (!dataRaw) {
                    const errorData = await errorFetch
                    throw new Error(
                        errorData?.message || "Unknown error occurred",
                    )
                }

                const data = await dataRaw
                const s3Connect = await getImageUrl(data.logo_path)
                setQrLogo(s3Connect || "")
                setQrLink(
                    getDomainName() +
                        "/chat/" +
                        data.bot_url.split("/").pop() || "",
                )
                console.log("Response data from the backend:", data)
            } catch (error: any) {
                console.error("Error in form submission:", error)
                showErrorDialog(error.message)
            }
        } catch (error) {
            console.error("Error converting files to base64:", error)
        }
    }

    const getDomainName = () => {
        const protocol = window.location.protocol
        const hostname = window.location.hostname
        const port = window.location.port

        if (hostname === "localhost" && port) {
            return `${protocol}//${hostname}:${port}`
        } else {
            return `${protocol}//${hostname}`
        }
    }

    const downloadQR = () => {
        const canvas = document.getElementById(
            "qrCodeCanvas",
        ) as HTMLCanvasElement
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        const downloadLink = document.createElement("a")
        downloadLink.href = pngUrl
        downloadLink.download = "QRCode.png"
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: formData.bot_name,
                    url: qrLink,
                })
                console.log("Content shared successfully")
            } catch (error) {
                console.error("Error sharing content", error)
            }
        } else {
            console.error("Web Share API is not supported in this browser.")
        }
    }

    return (
        <div className="flex flex-col h-screen bg-gray-100">
            <Header
                companyName="Create ChatBot"
                signOut={signOut}
                user={user}
            />
            <div className="flex-grow">
                <div className="flex flex-col items-center justify-center mt-8">
                    <div className="w-full max-w-md px-4">
                        <form
                            onSubmit={handleSubmit}
                            className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
                        >
                            <input
                                type="text"
                                name="bot_name"
                                value={formData.bot_name}
                                onChange={handleChange}
                                placeholder="Bot Name"
                                required
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {!formData.bot_nameValid && (
                                <p className="text-red-500 text-xs italic">
                                    Bot Name is required.
                                </p>
                            )}

                            <input
                                type="text"
                                name="bot_tag"
                                value={formData.bot_tag}
                                onChange={handleChange}
                                placeholder="Bot Tag"
                                required
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            {!formData.bot_tagValid && (
                                <p className="text-red-500 text-xs italic">
                                    Bot Tag is required.
                                </p>
                            )}

                            <div className="mb-4">
                                <label
                                    htmlFor="bot_logo"
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                    Bot Logo (Upload an image file)
                                </label>
                                <button
                                    type="button"
                                    onClick={() =>
                                        botLogoRef.current &&
                                        botLogoRef.current.click()
                                    }
                                    className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
                                >
                                    Choose File
                                </button>
                                <span className="ml-2 text-sm">
                                    {formData.bot_logo_name}
                                </span>
                                <input
                                    type="file"
                                    id="bot_logo"
                                    name="bot_logo"
                                    ref={botLogoRef}
                                    onChange={(e) =>
                                        handleFileChange(e, "bot_logo")
                                    }
                                    accept="image/*"
                                    required
                                    className="visually-hidden"
                                />
                            </div>
                            {!formData.bot_logoValid && (
                                <p className="text-red-500 text-xs italic">
                                    Bot Logo is required.
                                </p>
                            )}

                            <div className="mb-4">
                                <label
                                    htmlFor="bot_bg"
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                    Bot Background (Upload an image file)
                                </label>
                                <button
                                    type="button"
                                    onClick={() =>
                                        botBgRef.current &&
                                        botBgRef.current.click()
                                    }
                                    className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
                                >
                                    Choose File
                                </button>
                                <span className="ml-2 text-sm">
                                    {formData.bot_bg_name}
                                </span>
                                <input
                                    type="file"
                                    id="bot_bg"
                                    name="bot_bg"
                                    ref={botBgRef}
                                    onChange={(e) =>
                                        handleFileChange(e, "bot_bg")
                                    }
                                    accept="image/*"
                                    required
                                    className="visually-hidden"
                                />
                            </div>
                            {!formData.bot_bgValid && (
                                <p className="text-red-500 text-xs italic">
                                    Bot Background is required.
                                </p>
                            )}

                            <div className="mb-4">
                                <label
                                    htmlFor="bot_kb"
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                >
                                    Bot Knowledge Base (Upload a .docx or .pdf
                                    file)
                                </label>
                                <button
                                    type="button"
                                    onClick={() =>
                                        botKbRef.current &&
                                        botKbRef.current.click()
                                    }
                                    className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
                                >
                                    Choose File
                                </button>
                                <span className="ml-2 text-sm">
                                    {formData.bot_kb_name}
                                </span>
                                <input
                                    type="file"
                                    id="bot_kb"
                                    name="bot_kb"
                                    ref={botKbRef}
                                    onChange={(e) =>
                                        handleFileChange(e, "bot_kb")
                                    }
                                    accept=".docx,.pdf"
                                    required
                                    className="visually-hidden"
                                />
                            </div>
                            {!formData.bot_kbValid && (
                                <p className="text-red-500 text-xs italic">
                                    Bot Knowledge Base is required.
                                </p>
                            )}

                            <textarea
                                name="custom_prompt"
                                value={formData.custom_prompt}
                                onChange={handleChange}
                                placeholder="Custom Prompt"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            />

                            <button
                                type="submit"
                                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                            >
                                Create Bot
                            </button>
                        </form>
                    </div>
                    <Dialog
                        open={isErrorDialogOpen}
                        onClose={handleCloseErrorDialog}
                        className="relative z-50"
                    >
                        <div
                            className="fixed inset-0 bg-black/30"
                            aria-hidden="true"
                        />

                        <div className="fixed inset-0 flex items-center justify-center p-4">
                            <Dialog.Panel className="mx-auto max-w-md rounded bg-white p-6 text-center">
                                <Dialog.Title className="text-lg font-bold text-red-500">
                                    Error
                                </Dialog.Title>
                                <Dialog.Description className="mt-2">
                                    {error}
                                </Dialog.Description>
                                <button
                                    onClick={handleCloseErrorDialog}
                                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                >
                                    Close
                                </button>
                            </Dialog.Panel>
                        </div>
                    </Dialog>
                    {qrLink && (
                        <div className="p-4">
                            <QRCode
                                id="qrCodeCanvas"
                                value={qrLink}
                                size={200}
                                level={"H"}
                                includeMargin={true}
                                imageSettings={{
                                    src: qrLogo,
                                    excavate: true,
                                    height: 40,
                                    width: 40,
                                }}
                            />
                            <div className="mt-4 flex justify-center">
                                <button
                                    onClick={downloadQR}
                                    className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-700 flex items-center justify-center"
                                >
                                    <MdDownload className="mr-2" />
                                    Download
                                </button>
                                <button
                                    onClick={handleShare}
                                    className="ml-3 bg-indigo-500 text-white px-3 py-2 rounded hover:bg-indigo-700 flex items-center justify-center"
                                >
                                    <MdShare className="mr-2" />
                                    Share
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer href="https://kibandai.com/" poweredBy="KibandAI" />
        </div>
    )
}

export default CreateBot
