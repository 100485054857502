import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useFetch } from "../hooks/useFetch"
import { useAwsS3 } from "../utils/awsS3"
import { Dialog } from "@headlessui/react"
import BeatLoader from "react-spinners/BeatLoader"
import { useParams } from "react-router-dom"
import { type AuthUser } from "aws-amplify/auth"
import { type UseAuthenticator } from "@aws-amplify/ui-react-core"

interface ClientHomeProps {
    user: AuthUser
    signOut: UseAuthenticator["signOut"]
}

const ClientHome: React.FC<ClientHomeProps> = ({ user, signOut }) => {
    const navigate = useNavigate()
    const {
        fetchWithToken,
        isLoading: fetchLoading,
        error: fetchError,
    } = useFetch()
    const [error, setError] = useState<string | null>(null)
    const { getImageUrl, isLoading: awsLoading, error: awsError } = useAwsS3()
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false)
    const showErrorDialog = (errorMessage: string) => {
        setError(errorMessage)
        setIsErrorDialogOpen(true)
    }
    const email = user ? user?.signInDetails?.loginId : ""
    const [loading, setLoading] = useState(true)
    const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({})

    const handleCloseErrorDialog = () => {
        setIsErrorDialogOpen(false)
        setError(null)
    }
    const [bots, setBots] = useState<any>([])

    const getAllDetails = async () => {
        console.log(email)
        const payload = {
            email: `${email}`,
        }
        try {
            const apiUrl =
                process.env.REACT_APP_API_ENDPOINT + "/get_user_details"
            console.log(apiUrl)
            const dataRaw = await fetchWithToken(apiUrl, {
                method: "POST",
                body: JSON.stringify(payload),
                headers: { "Content-Type": "application/json" },
            })

            if (!dataRaw) {
                console.log(dataRaw)
            }

            const data = await dataRaw
            console.log("Response data from the backend:", data)
            setBots(data)
        } catch (error: any) {
            console.error("Error in form submission:", error)
            showErrorDialog(error.message)
        }
    }

    useEffect(() => {
        getAllDetails()
    }, [])

    useEffect(() => {
        setLoading((fetchLoading || awsLoading) === true)
    }, [fetchLoading, awsLoading])

    useEffect(() => {
        const fetchImageUrls = async () => {
            const urls: { [key: string]: string } = {}
            for (const bot of bots) {
                try {
                    const url = await getImageUrl(bot.bot_logo)
                    if (url) {
                        urls[bot.bot_uuid] = url
                    }
                } catch (error) {
                    console.error(
                        "Error fetching image for bot:",
                        bot.bot_name,
                        error,
                    )
                }
            }
            setImageUrls(urls)
        }

        fetchImageUrls()
    }, [bots])

    return (
        <div className="flex flex-col h-screen">
            <Header companyName="Admin Portal" signOut={signOut} user={user} />
            {loading ? (
                <div className="flex-grow flex items-center justify-center">
                    <BeatLoader color="#4A90E2" />{" "}
                </div>
            ) : (
                <>
                    <div className="flex flex-col flex-grow overflow-auto">
                        <div className="flex flex-col sm:flex-row justify-around items-center height-30 bg-[#B7D7D8] p-4">
                            <button
                                onClick={() => navigate("/create-bot")}
                                className="bg-gray-600 font-dosis hover:bg-gray-700 text-white font-bold py-2 px-4 rounded m-1"
                            >
                                Create Bot
                            </button>
                            {/* <button className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded m-1">
                                Dummy Button
                            </button> */}
                        </div>
                        <div className="height-70 bg-[#E7F1F2] p-4 overflow-auto">
                            <div className="flex flex-wrap justify-around">
                                {bots.map((bot: any, index: number) => (
                                    <button
                                        key={index}
                                        className="bg-gray-600 font-dosis hover:bg-gray-700 text-white font-bold py-2 px-4 rounded m-2 flex items-center justify-start min-w-0"
                                        style={{
                                            minWidth: "120px",
                                            maxWidth: "calc(50% - 16px)",
                                        }}
                                        onClick={() =>
                                            navigate(`/chat/${bot.bot_uuid}`)
                                        }
                                    >
                                        <img
                                            src={imageUrls[bot.bot_uuid]}
                                            alt={`${bot.bot_name} logo`}
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                marginRight: "10px",
                                            }}
                                        />
                                        {bot.bot_name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Dialog
                        open={isErrorDialogOpen}
                        onClose={handleCloseErrorDialog}
                        className="relative z-50"
                    >
                        <div
                            className="fixed inset-0 bg-black/30"
                            aria-hidden="true"
                        />

                        <div className="fixed inset-0 flex items-center justify-center p-4">
                            <Dialog.Panel className="mx-auto max-w-md rounded bg-white p-6 text-center">
                                <Dialog.Title className="text-lg font-bold text-red-500">
                                    Error
                                </Dialog.Title>
                                <Dialog.Description className="mt-2">
                                    {error}
                                </Dialog.Description>
                                <button
                                    onClick={handleCloseErrorDialog}
                                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                >
                                    Close
                                </button>
                            </Dialog.Panel>
                        </div>
                    </Dialog>
                </>
            )}

            <Footer href="https://kibandai.com" poweredBy="KibandAI" />
        </div>
    )
}

export default ClientHome
