import React from "react"

const Home: React.FC = () => {
    console.log(`${process.env.PUBLIC_URL}/kibdandai/index.html`)
    return (
        <iframe
            src={"/kibdandai/templates/index.html"}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title="Embedded HTML Application"
        >
            <p>Your browser does not support iframes.</p>
        </iframe>
    )
}

export default Home
