import React, { ReactElement } from "react"
import { Authenticator } from "@aws-amplify/ui-react"
import { AuthUser } from "aws-amplify/auth"

interface ProtectedRouteProps {
    children: ReactElement
}

const formFields = {
    signUp: {
        email: {
            order: 1,
        },
        password: {
            order: 2,
        },
        confirm_password: {
            order: 3,
        },
        name: {
            order: 4,
            label: "Your Name",
        },
        "custom:company_name": {
            order: 5,
            placeholder: "Enter your Company name here",
            isRequired: true,
            label: "Company Name",
        },
        "custom:company_abbreviation": {
            order: 6,
            placeholder: "Enter your Company Abbrivation here (eg: abc-inc)",
            isRequired: true,
            label: "Company Abbrivation",
        },
    },
}

const styles = {
    container: {
        // Example styles
        padding: "20px",
        margin: "20px",
        // Add other styling as needed
    },
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    return (
        <Authenticator className="p-3 m-20" formFields={formFields}>
            {({ signOut, user }: { signOut?: () => void; user?: AuthUser }) => (
                <>
                    {user
                        ? React.cloneElement(children, { user, signOut })
                        : null}
                </>
            )}
        </Authenticator>
    )
}

export default ProtectedRoute
