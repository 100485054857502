// useFetch.ts
import React, { useState } from "react"
import axios from "axios"
import {
    LambdaClient,
    InvokeCommand,
    InvokeCommandInput,
    LogType,
} from "@aws-sdk/client-lambda"
import { Buffer } from "buffer"

type UseFetchReturnType = {
    fetchWithToken: (url: string, options: any) => Promise<any>
    isLoading: boolean
    error: Error | null
}

export function useFetch(): UseFetchReturnType {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const invoke = async (
        funcName = process.env.REACT_APP_UI_AUTHORIZATION_LAMBDA,
    ): Promise<{ logs: string; result: string }> => {
        const client = new LambdaClient({
            region: process.env.REACT_APP_REGION || "",
            credentials: {
                accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || "",
                secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY || "",
            },
        })

        const commandInput: InvokeCommandInput = {
            FunctionName: funcName,
            InvocationType: "RequestResponse",
        }
        const command = new InvokeCommand(commandInput)

        const { Payload, LogResult } = await client.send(command)
        const result = Payload ? Buffer.from(Payload).toString() : ""
        const logs = LogResult
            ? Buffer.from(LogResult, "base64").toString()
            : ""
        return { logs, result }
    }

    const fetchWithToken = async (url: string, options: any): Promise<any> => {
        setIsLoading(true)
        setError(null)

        const handleError = (err: unknown) => {
            if (err instanceof Error) {
                console.error(err.message)
                setError(err)
            } else {
                console.error("An unexpected error occurred", err)
                setError(new Error("An unexpected error occurred"))
            }
            setIsLoading(false)
        }

        try {
            const value = await invoke(
                process.env.REACT_APP_UI_AUTHORIZATION_LAMBDA,
            )

            if (value) {
                const response = await axios({
                    url,
                    method: options.method || "GET",
                    headers: {
                        ...options.headers,
                        Authorization: `Bearer ${
                            JSON.parse(JSON.parse(value.result).body)
                                .access_token
                        }`,
                    },
                    data: options.body,
                })
                setIsLoading(false)
                return response.data
            } else {
                throw new Error("No token available")
            }
        } catch (err: unknown) {
            handleError(err)
            throw err // Re-throw the error if you need to handle it outside the hook as well.
        }
    }

    return { fetchWithToken, isLoading, error }
}
