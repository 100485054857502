import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import ClientChat from "./pages/ClientChat"
import ClientHome from "./pages/ClientHome"
import CreateBot from "./pages/CreateBot"
import ProtectedRoute from "./pages/ProtectedRoute"
import "./App.css"
import { type AuthUser } from "aws-amplify/auth"
import { type UseAuthenticator } from "@aws-amplify/ui-react-core"
import { Amplify } from "aws-amplify"
import awsconfig from "./aws-exports"

import "@aws-amplify/ui-react/styles.css"

Amplify.configure(awsconfig)

type AppProps = {
    signOut?: UseAuthenticator["signOut"]
    user?: AuthUser
}

const App: React.FC<AppProps> = ({ signOut, user }) => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/chat/:companyURI" element={<ClientChat />} />
                <Route
                    path="/portal"
                    element={
                        <ProtectedRoute>
                            {React.createElement(
                                ClientHome as React.ComponentType,
                            )}
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/create-bot"
                    element={
                        <ProtectedRoute>
                            {React.createElement(
                                CreateBot as React.ComponentType,
                            )}
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    )
}

export default App
