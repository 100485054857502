import React, { useState, useEffect, useRef, RefObject } from "react"
import ReactMarkdown from "react-markdown"
import {
    UserCircleIcon,
    PaperAirplaneIcon,
    TrashIcon,
} from "@heroicons/react/24/outline"
import { useParams } from "react-router-dom"
import { BsRobot } from "react-icons/bs"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { useFetch } from "../hooks/useFetch"
import { useAwsS3 } from "../utils/awsS3"
import BeatLoader from "react-spinners/BeatLoader"

type ChatMessage = {
    sender: string
    message: string
    video?: string
}

const LoadingDots = () => {
    return <div className="dot-flashing"></div>
}

interface CompanyConfig {
    headerLogo: string
    companyLogo: string
    qrImage: string
    qrLink: string
    companyName: string
    customPrompts: string[]
}

const companyConfig: Record<string, CompanyConfig> = {
    "innovative-sleep-systems": {
        headerLogo: "/images/SSI.jpeg",
        companyLogo: "/images/SSI1.jpeg",
        qrImage: "/images/SSI1.jpeg",
        qrLink: "https://kibandai.com/chat/Innovative-Sleep-Systems",
        companyName: "Innovative-Sleep-Systems",
        customPrompts: [
            "What is the price of the mattress?",
            "What are the dimensions of the mattress?",
            "What is smart about the mattress?",
            "Tell me about king koil mattress?",
        ],
    },
    "freed-income-fund": {
        headerLogo: "/images/freed.jpeg",
        companyLogo: "/images/freed.jpeg",
        qrImage: "/images/freed.jpeg",
        qrLink: "https://freedlink.com",
        companyName: "Freed-Income-Fund",
        customPrompts: [
            "Who is the issuer of the fund?",
            "who can invest with you ?",
            "Is there a management fee?",
            "What are their portfolio assets on King Street properties?",
        ],
    },
    "electrical-saftey-authority": {
        headerLogo: "/images/electrical.png",
        companyLogo: "/images/electrical.png",
        qrImage: "/images/electrical.png",
        qrLink: "https://electricallink.com",
        companyName: "Electrical-Safety-Authority",
        customPrompts: [
            "Tell me about the document?",
            "What is electrical safety?",
            "what are the precautions?",
        ],
    },
}

const ClientChat = () => {
    const [sessionExists, setSessionExists] = useState(false)
    const [validURlExists, setValidURLExists] = useState(false)
    const [dataloading, setDataLoading] = useState(true)
    const [isSending, setIsSending] = useState(false)
    const [ws, setWs] = useState<WebSocket | null>(null)
    const [webSocketLoading, setWebSocketLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [chatHistory, setChatHistory] = useState<ChatMessage[]>([])
    const chatInputRef = useRef<HTMLTextAreaElement>(null)
    const chatHistoryRef = useRef<HTMLDivElement>(null)
    const [headerLogo, setHeaderLogo] = useState("")
    const [companyLogo, setCompanyLogo] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [qrLink, setQrLink] = useState("")
    const [qrImage, setQrImage] = useState("")
    const { companyURI } = useParams<{ companyURI: string }>()
    const [showPrompts, setShowPrompts] = useState(true)
    const [socketConnection, setSocketConnection] = useState<boolean>(true)
    const [customPrompts, setCustomPrompts] = useState<string[]>([])
    const [assetsData, setAssetsData] = useState<any>(null)
    const { fetchWithToken, isLoading, error: fetchError } = useFetch()
    const [error, setError] = useState<string | null>(null)
    const { getImageUrl, isLoading: awsLoading, error: awsError } = useAwsS3()

    const checkValidURL = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const someParam = urlParams.get("someParam")
        return someParam === "expectedValue"
    }

    const getAllDetails = async () => {
        const payload = {
            bot_uuid: companyURI?.toLocaleLowerCase(),
        }
        try {
            const apiUrl: string =
                process.env.REACT_APP_API_ENDPOINT + "/chat_assets" || ""
            const dataRaw = await fetchWithToken(apiUrl, {
                method: "POST",
                body: JSON.stringify(payload),
                headers: { "Content-Type": "application/json" },
            })

            if (!dataRaw) {
                console.log(dataRaw)
                const errorData = await dataRaw
                throw new Error(errorData.message || "Unknown error occurred")
            }

            const data = await dataRaw
            setAssetsData(data)
        } catch (error: any) {
            console.error("Error in form submission:", error)
            // showErrorDialog(error.message)
        }
    }

    useEffect(() => {
        getAllDetails()
    }, [])

    const getTheImage = async (url: string) => {
        const val = await getImageUrl(url)
        return val
    }

    useEffect(() => {
        setLoading(dataloading || webSocketLoading)
    }, [awsLoading, dataloading, webSocketLoading])

    useEffect(() => {
        const fetchImagesAndSetStates = async () => {
            if (companyURI) {
                const config: any =
                    assetsData || companyConfig[companyURI.toLowerCase()]

                const fetchedHeaderLogo =
                    config?.headerLogo || (await getTheImage(config?.bot_logo))
                const fetchedCompanyLogo =
                    config?.companyLogo || (await getTheImage(config?.bot_bg))
                const fetchedQrImage =
                    config?.qrImage || (await getTheImage(config?.bot_bg))

                setHeaderLogo(fetchedHeaderLogo)
                setCompanyLogo(fetchedCompanyLogo)
                setQrImage(fetchedQrImage)
                setQrLink(getDomainName() + "/chat/" + companyURI)
                setCompanyName(config?.companyName || config?.bot_name)
                setCustomPrompts(
                    companyConfig[companyURI?.toLowerCase()]?.customPrompts ||
                        [],
                )
                setDataLoading(false)
            }
        }

        fetchImagesAndSetStates()
    }, [companyURI, assetsData])

    useEffect(() => {
        setSessionExists(true)
        setValidURLExists(true)
        initializeWebSocket()
        setChatHistory([])

        return () => {
            if (ws) {
                ws.close()
            }
        }
    }, [])

    const updateChatHistory = ({ sender, message, video }: ChatMessage) => {
        setChatHistory((prevHistory) => {
            const updatedHistory = [...prevHistory, { sender, message, video }]
            localStorage.setItem("chatHistory", JSON.stringify(updatedHistory))
            return updatedHistory
        })
    }

    const initializeWebSocket = () => {
        const socket = new WebSocket(
            process.env.REACT_APP_QA_SOCKET_ENDPOINT +
                "/dev?bot_uuid=" +
                companyURI,
        )
        setWs(socket)

        socket.onopen = () => {
            console.log("WebSocket Connected")
            setSocketConnection(true)
            setWebSocketLoading(false)
        }

        socket.onmessage = (event) => {
            const receivedData = JSON.parse(event.data)
            const receivedMessage = {
                sender: "bot",
                message: receivedData[0].message,
                video: receivedData[0].video || "",
            }
            updateChatHistory(receivedMessage)
            setIsSending(false)
        }

        socket.onclose = () => {
            console.log("WebSocket Disconnected")
            setSocketConnection(false)
            setIsSending(false)
            setWs(null)
        }
    }

    const getDomainName = () => {
        const protocol = window.location.protocol
        const hostname = window.location.hostname
        const port = window.location.port

        if (hostname === "localhost" && port) {
            return `${protocol}//${hostname}:${port}`
        } else {
            return `${protocol}//${hostname}`
        }
    }

    const handleOutgoingChat = () => {
        if (chatInputRef.current && ws) {
            const message = chatInputRef.current.value
            console.log(message)
            if (ws && message.trim()) {
                setIsSending(true)
                const msg = [
                    {
                        message: message,
                    },
                ]
                ws.send(JSON.stringify(msg))
                updateChatHistory({
                    sender: "user",
                    message: message,
                })
                chatInputRef.current.value = ""
            }
        }
    }

    const handlePromptClick = (prompt: string) => {
        if (prompt && ws) {
            const message = prompt
            if (ws && message.trim()) {
                setIsSending(true)
                const msg = [
                    {
                        message: message,
                    },
                ]
                ws.send(JSON.stringify(msg))
                updateChatHistory({
                    sender: "user",
                    message: message,
                })
            }
        }
        setShowPrompts(false)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            setShowPrompts(false)
        } else {
            setShowPrompts(chatInputRef.current?.value === "")
        }
    }

    const handleDeleteChats = () => {
        setChatHistory([])
        localStorage.removeItem("chatHistory")
    }

    const ChatMessage = ({ sender, message, video }: ChatMessage) => {
        const isUser = sender === "user"

        return (
            <div className={"message flex justify-start mb-2 z-30"}>
                {!isUser && <BsRobot className="h-6 w-6 text-black-500 mr-2" />}
                {isUser && (
                    <UserCircleIcon className="h-6 w-6 text-gray-800 mr-2" />
                )}
                <div
                    className={`p-2 font-dosis ${
                        isUser ? "bg-[#ffffff]" : "bg-[#CAD3D3]"
                    } rounded-xl w-full border border-black`}
                >
                    <ReactMarkdown
                        className={`markdown-style ${
                            isUser ? "text-black" : "text-black"
                        }`}
                        components={{
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            a: ({ node, ...props }) => (
                                <a
                                    {...props}
                                    className="font-bold"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            img: ({ node, ...props }) => (
                                <img
                                    {...props}
                                    className="mx-auto my-4"
                                    style={{ maxWidth: "60%", height: "auto" }}
                                />
                            ),
                        }}
                    >
                        {message}
                    </ReactMarkdown>
                    {video && (
                        <div className="video-container mt-4 flex justify-center">
                            <iframe
                                width="60%
                                "
                                height="250"
                                src={video}
                                title="Video Content"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (chatHistoryRef.current) {
            const { scrollHeight, clientHeight } = chatHistoryRef.current
            chatHistoryRef.current.scrollTop = scrollHeight - clientHeight
        }
    }, [chatHistory, chatHistoryRef])

    type ChatInterfaceProps = {
        chatHistory: ChatMessage[]
        onSendMessage: () => void
        onDeleteMessages: () => void
        chatInputRef: RefObject<HTMLTextAreaElement>
    }

    const ChatInterface = ({
        chatHistory,
        onSendMessage,
        onDeleteMessages,
        chatInputRef,
    }: ChatInterfaceProps) => {
        const handleKeyDown = (
            event: React.KeyboardEvent<HTMLTextAreaElement>,
        ) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault()
                onSendMessage()
                setShowPrompts(false)
            }
            // else {
            //     setShowPrompts(chatInputRef.current?.value === "")
            // }
        }
        const backgroundImageStyle: React.CSSProperties = {
            backgroundImage: `url(${companyLogo})`,
            backgroundSize: "50%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            opacity: 0.6,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "10px",
        }

        return (
            <div className="chat-interface flex flex-col h-screen bg-[#E7F1F2]">
                <Header
                    companyName={companyName}
                    headerLogo={headerLogo}
                    qrLink={qrLink}
                    qrImage={qrImage}
                />
                <div style={backgroundImageStyle}></div>
                <div
                    ref={chatHistoryRef}
                    className="chat-history overflow-auto flex-1 mx-4 sm:mx-12 md:mx-16 lg:mx-24 xl:mx-32 mt-3 z-20"
                >
                    <div className="message flex justify-start mb-2 z-30">
                        <BsRobot className="h-6 w-6 text-black-500 mr-2" />
                        <div
                            className={
                                "p-2 font-dosis bg-[#CAD3D3] rounded-xl w-full border border-black"
                            }
                        >
                            <ReactMarkdown
                                className={"markdown-style text-black"}
                            >
                                {`Welcome to ${companyName} Virtual Assistant. How
                                can I help you?`}
                            </ReactMarkdown>{" "}
                        </div>
                    </div>
                    {chatHistory.map((msg, index) => (
                        <div className="z-20" key={"k" + index}>
                            <ChatMessage
                                key={index}
                                sender={msg?.sender}
                                message={msg?.message}
                                video={msg?.video}
                            />
                        </div>
                    ))}
                    {isSending && (
                        <div className="z-20">
                            <BsRobot className="h-6 w-6 text-black-500 mr-2" />
                            <LoadingDots />
                        </div>
                    )}
                    {!socketConnection && (
                        <div className={"message flex justify-start mb-2 z-20"}>
                            <BsRobot className="h-6 w-6 text-black-500 mr-2" />
                            <div
                                className={
                                    "p-2 font-dosis bg-red-300 rounded-lg w-full"
                                }
                            >
                                <ReactMarkdown>
                                    Connection Failed, please reload the page or
                                    try again later.
                                </ReactMarkdown>
                            </div>
                        </div>
                    )}
                    {showPrompts && !!chatHistory && (
                        <div className="top-0 transform mt-4 flex flex-col items-center z-20">
                            {customPrompts?.length > 0 &&
                                customPrompts.map((prompt, index) => (
                                    <button
                                        key={index}
                                        onClick={() =>
                                            handlePromptClick(prompt)
                                        }
                                        className="text-sm bg-white text-gray-800 py-1 px-3 rounded-full shadow mb-2 z-20"
                                    >
                                        {prompt}
                                    </button>
                                ))}
                        </div>
                    )}
                </div>
                <div className="p-4 bg-[#CAD3D3] flex items-center z-20">
                    <textarea
                        ref={chatInputRef}
                        placeholder="Type your message here..."
                        className="flex-1 p-2 border rounded mr-2 text-sm font-dosis"
                        onKeyDown={handleKeyDown}
                    ></textarea>
                    <button
                        onClick={onSendMessage}
                        className="bg-gray-600 hover:bg-gray-700 text-white p-2 rounded"
                    >
                        <PaperAirplaneIcon className="h-5 w-5" />
                    </button>
                    <button
                        onClick={onDeleteMessages}
                        className="bg-red-500 hover:bg-red-600 text-white p-2 rounded ml-2"
                    >
                        <TrashIcon className="h-5 w-5" />
                    </button>
                </div>
                <Footer href="https://kibandai.com" poweredBy="KibandAI" />
            </div>
        )
    }

    const ConnectionError = () => {
        return (
            <div className="error-message">
                Connection not established or chatbot not found.
                {/* <a href="/app/contact">Contact Support</a> */}
            </div>
        )
    }

    const InvalidURLError = () => {
        return (
            <div className="error-message">
                Invalid URL. Session not found.
                {/* <a href="/contact">Contact Support</a> */}
            </div>
        )
    }

    const getContent = () => {
        if (sessionExists && validURlExists) {
            return (
                <ChatInterface
                    chatHistory={chatHistory}
                    onSendMessage={handleOutgoingChat}
                    onDeleteMessages={handleDeleteChats}
                    chatInputRef={chatInputRef}
                />
            )
        } else if (validURlExists && !sessionExists) {
            return <ConnectionError />
        } else {
            return <InvalidURLError />
        }
    }

    const Loading = () => {
        return (
            <div className="flex flex-col h-screen">
                <div className="flex-grow flex items-center justify-center">
                    <BeatLoader color="#4A90E2" />{" "}
                </div>
            </div>
        )
    }

    return <>{loading ? <Loading /> : getContent()}</>
}

export default ClientChat
