import { CommonMappings } from "../types"

export const getExtensionFromMimeType = async (
    mimeType: string,
): Promise<string | null> => {
    try {
        const response = await fetch("mime.json")
        const mappings: CommonMappings = await response.json()

        const mimeEntry = mappings[mimeType]
        if (
            mimeEntry &&
            mimeEntry.extensions &&
            mimeEntry.extensions.length > 0
        ) {
            return mimeEntry.extensions[0]
        } else {
            console.log(`No extension found for MIME type: ${mimeType}`)
            return null
        }
    } catch (error) {
        console.error(`Error fetching common mappings: ${error}`)
        return null
    }
}
